exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-wall-labels-js": () => import("./../../../src/pages/wall-labels.js" /* webpackChunkName: "component---src-pages-wall-labels-js" */),
  "component---src-templates-paint-detail-template-js": () => import("./../../../src/templates/paintDetailTemplate.js" /* webpackChunkName: "component---src-templates-paint-detail-template-js" */),
  "component---src-templates-serie-gallery-template-js": () => import("./../../../src/templates/serieGalleryTemplate.js" /* webpackChunkName: "component---src-templates-serie-gallery-template-js" */)
}

